var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "mx-auto",
                  attrs: {
                    align: "center",
                    justify: "center",
                    cols: "12",
                    sm: "8",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { align: "start", justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: { color: "primary" },
                          on: {
                            click: function () {
                              _vm.$router.go(-1)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                    [
                      _c(
                        "v-toolbar",
                        { staticClass: "secondary white--text pa-1" },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h5" }, [
                            _vm._v(" Demonstration Request "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      readonly: true,
                                      label: "First Name",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.fname,
                                      callback: function ($$v) {
                                        _vm.fname = $$v
                                      },
                                      expression: "fname",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      readonly: true,
                                      label: "Last Name",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.lname,
                                      callback: function ($$v) {
                                        _vm.lname = $$v
                                      },
                                      expression: "lname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: true,
                                  dense: "",
                                  outlined: "",
                                  label: "Email",
                                  type: "email",
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: true,
                                  dense: "",
                                  outlined: "",
                                  label: "Mobile",
                                },
                                model: {
                                  value: _vm.mobile,
                                  callback: function ($$v) {
                                    _vm.mobile = $$v
                                  },
                                  expression: "mobile",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: true,
                                  dense: "",
                                  outlined: "",
                                  label: "Company",
                                },
                                model: {
                                  value: _vm.company,
                                  callback: function ($$v) {
                                    _vm.company = $$v
                                  },
                                  expression: "company",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c("v-select", {
                                attrs: {
                                  readonly: true,
                                  items: _vm.statusOptions,
                                  label: "Status",
                                  dense: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.status,
                                  callback: function ($$v) {
                                    _vm.status = $$v
                                  },
                                  expression: "status",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-start" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  readonly: true,
                                  solo: "",
                                  counter: 500,
                                  rules: _vm.rulesMaxLength,
                                  label: "Please insert your remark",
                                },
                                model: {
                                  value: _vm.remark,
                                  callback: function ($$v) {
                                    _vm.remark = $$v
                                  },
                                  expression: "remark",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", plain: "" },
                                  on: {
                                    click: function () {
                                      _vm.$router.go(-1)
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.api.isLoading,
                                  },
                                  on: { click: _vm.validateInput },
                                },
                                [_vm._v(" Confirm ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }